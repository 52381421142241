.wrapper{
    :global{
        .flowchart-toolbar{
            width: 70px;
            .flowchart-toolbar-item{
                padding-bottom: 10px;
                box-sizing: content-box;
                margin: 10px;
                ellipse, rect{
                    stroke: #000;
                }
            }
        }
    }
}